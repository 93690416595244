import slugify from 'slugify';
import { createSelector } from 'reselect';

import callApi from '../../util/apiCaller.js';

import { sendSocket } from '../App/AppActions.js';
import { getTranslation } from '../Intl/IntlHelper.js';
import { USER_GROUP_DEFAULT } from '../User/UserGroupActions.js';
import { addError } from '../Error/ErrorActions.js';
import { dateAdd, dateEndOf, dateFormat, dateIsBefore, toDate } from '../../util/date.js';

// Export Constants
export const SET_PRODUCTS = 'SET_PRODUCTS';

// Export Actions

export function getProductsRequest(isAll = false) {
    return dispatch => {
        return callApi(`products${isAll ? '/all' : ''}`).then(res => {
            if(res.products) {
                dispatch(setProducts(res.products));
            }
            return res.products;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function editProductRequest(product, fetchAll = true) {
    return dispatch => {
        return callApi('product/edit', 'post', { product }).then(res => {
            if(res.product) {
                fetchAll && dispatch(getProductsRequest(true));
                dispatch(sendSocket({ type: 'products/update' }));
            }
            return res.product;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function setProductStockRequest(productId, newStock) {
    return dispatch => {
        return callApi('product/stock', 'post', { product: { _id: productId }, stock: newStock }).then(res => {
            if(res.ok) {
                dispatch(getProductsRequest(true));
                dispatch(sendSocket({ type: 'products/update' }));
            }
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function exportProductRequest(product) {
    return dispatch => {
        return callApi('product/export', 'get').then(res => {
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function checkStockForecastRequest(options = {}) {
	return callApi(`products/stock/forecast?${Object.keys(options).filter(key => options[key]).map(key => `${key}=${options[key]}`).join('&')}`);
}

// export function setLocaleFromProduct(store, companySlug, productSlug) {
//     return dispatch => {
//         return new Promise((resolve, reject) => {
//             const company = getCompanyBySlug(store, companySlug);
//             return enabledLanguages.some(lang => {
//                 const product = company && getProductBySlug(store, company._id, productSlug, lang);
//                 if(product) {
//                     // console.log(productSlug, product.slug, getTranslation(product, 'slug', lang), getProductSlug(product, lang));
//                     // FR => default language for name, slug, ...
//                     if(getProductSlug(product, lang) === productSlug) {
//                         dispatch(initLanguage(lang));
//                         resolve(lang);
//                         return true;
//                     }
//                     resolve();
//                     return false;
//                 }
//                 resolve();
//                 return false;
//             });
//         });
//     };
// }

export function getProductsFromStore(store) {
    return store.products.data || [];
}

export function getFilteredProducts(products, filters = { isActive: null, isPack: null, isHighlight: null, isVariation: null }) {
    // console.log('getFilteredProducts', filters);
    Object.keys(filters || {}).forEach(key => {
        if(filters[key] !== null) {
            products = products.filter(product => {
                // product[key] !== 'undefined' && product[key] !== filters[key] && console.log('Prorduct::: get from store::: Exclude', product, key, filters[key]);
                return typeof product[key] === 'undefined' || product[key] === filters[key];
            });
        }
    });
    // console.log('products filtered', products);
    return products;
}

export const memoizedGetProducts = createSelector(
    [
		getProductsFromStore,
		(store, filters) => filters,
	],
    getFilteredProducts,
);

export function getProducts(store, filters = {}) {
    return getFilteredProducts(getProductsFromStore(store), filters);
}

export function getPackProducts(store, pack) {
    const products = getProducts(store);
    return (pack.items || []).map(item => products.find(product => product._id === item.productId));
}

export function getProduct(store, productId) {
    return store.products.data.find(product => product._id === productId);
}

export function getProductBySlug(store, productSlug, lang = null) {
    return store.products.data.find(product => {
        return getProductSlug(product, lang || store.intl.locale) === (productSlug && productSlug.toLowerCase());
    });
}

export function getProductSlug(product, lang) {
    return (getTranslation(product, 'slug', lang) && getTranslation(product, 'slug', lang).toLowerCase()) || slugify(getTranslation(product, 'name', lang)).toLowerCase();
}

export function getProductsAttributeList(store, attributeName, lang = null) {
    let attributes = [];
    getProducts(store).forEach(product => {
        const productAttributes = getProductAttributeList(product, attributeName, lang || store.intl.locale);
        if(productAttributes) {
            attributes = attributes.concat(productAttributes);
        }
    });
    return attributes.filter((attribute, index, self) => self.indexOf(attribute) === index) || [];
}

export function getProductAttributeList(product, attributeName, lang) {
    return getTranslation(product, attributeName, lang) || [];
}

export function getProductPrice(product, quantity = 1, userGroup = null, inclTax = true, referral = null) {
    let price = 0;
    const specialPrice = getProductSpecialPrice(product, 'priceTTC', quantity, userGroup || USER_GROUP_DEFAULT, referral);
    // console.log(product.name, userGroup, specialPrice);
    if(product) {
        price = parseFloat((specialPrice || {}).value || product.priceTTC) * (quantity || 1);
    }
    if((userGroup || {}).discount && !((specialPrice || {}).groups || []).length) { // Do not re apply user group discount if special price is set for this group !
        price -= ((price * userGroup.discount) / 100);
    }
    if(!inclTax) {
        price /= (1 + parseFloat(product.taxRate));
    }
    return price;
}

export function getProductSpecialPrices(product, key = 'priceTTC', quantity = null, userGroup = null, referral = null) {
	return ((product || {}).specialPrices || []).filter(specialPrice => filterProductSpecialPrice(specialPrice, key, quantity, userGroup, referral));
}

export function getProductSpecialPrice(product, key = 'priceTTC', quantity = null, userGroup = null, referral = null) {
    // console.log('getProductSpecialPrice', (product || {}).specialPrices, key, userGroup.identifier || userGroup, referral);
    return ((product || {}).specialPrices || []).sort((spA, spB) => spB.quantity - spA.quantity).find(specialPrice => filterProductSpecialPrice(specialPrice, key, quantity, userGroup, referral)) || (referral && getProductSpecialPrice(product, key, quantity, userGroup)) || null;
}

export function filterProductSpecialPrice(specialPrice, key, quantity = null, userGroup = null, referral = null) {
	// console.log(specialPrice);
	// console.log('key', specialPrice.key === key);
	// console.log('group', ((!userGroup && !(specialPrice.groups || []).length) || (specialPrice.groups || []).includes((userGroup || {}).identifier || userGroup)));
	// console.log('referral', (!specialPrice.referral || specialPrice.referral === referral));
	// console.log('dateThreshold', (!specialPrice.dateThreshold || dateIsBefore(null, getProductSpecialPriceDateThreshold(specialPrice))));
	// console.log(specialPrice, 'Quantity test', quantity, (quantity === null || !specialPrice.quantity || parseInt(quantity, 10) >= parseInt(specialPrice.quantity || 0, 10)));
	userGroup = userGroup || USER_GROUP_DEFAULT;
	return (
		(!specialPrice.tag || specialPrice.tag !== 'model')
		&& specialPrice.key === key
		&& ((!userGroup && !(specialPrice.groups || []).length) || (specialPrice.groups || []).includes((userGroup || {}).identifier || userGroup))
		&& (!specialPrice.referral || specialPrice.referral === referral)
		&& (!specialPrice.dateThreshold || dateIsBefore(new Date(), getProductSpecialPriceDateThreshold(specialPrice)))
		&& (quantity === null || !specialPrice.quantity || parseInt(quantity, 10) >= parseInt(specialPrice.quantity || 0, 10))
	);
}

export function getProductSpecialPriceValue(product, key = 'priceTTC', quantity = 1, userGroup = null, referral = null) {
    return parseFloat((getProductSpecialPrice(product, key, quantity, userGroup, referral) || {}).value || 0);
}

export function getProductSpecialPriceDateThreshold(specialPrice) {
	if(specialPrice?.dateThreshold) {
		let date = toDate(specialPrice.dateThreshold);

		// Add X days to now
		if(specialPrice.dateThreshold.includes('+')) {
			// console.log(`date threshold +${parseInt(specialPrice.dateThreshold, 10)} Jours`);
			date = dateAdd(new Date(), parseInt(specialPrice.dateThreshold, 10), 'days');

		// Set next date to next day of month (for value of 8 => date is next 8th of the month)
		} else if(!specialPrice.dateThreshold.includes('/')) {
			let diffDays = parseInt(specialPrice.dateThreshold - dateFormat(new Date(), 'D'), 10);
			if(diffDays < 0) {
				diffDays = 31 + diffDays;
			}
			date = dateAdd(new Date(), diffDays, 'days');
		} else if(!specialPrice.dateThreshold.includes(':')) {
			return dateEndOf(date, 'day');
		}
		// console.log('date threshold', specialPrice, specialPrice.dateThreshold, dateFormat(date, 'LL'));
		return date;
	}
	return null;
}

export function getProductOption(product, option) {
    return (product && product.options && product.options[option]) || '';
}

export function setProducts(products) {
    return {
        type: SET_PRODUCTS,
        products,
    };
}
