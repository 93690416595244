import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import onScan from 'onscan.js';

import { Button } from 'reactstrap';
import { addScan, clearScans, getScans } from '../../AppActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getProducts } from '../../../Product/ProductActions';

function ScannerInit({ dispatch, user = null, scans = [], products = [] }) {
	const init = () => {
		if(['admin', 'manager'].includes(user?.role)) {
			console.log('Scan::: allowed');
			// Enable scan events for the entire document
			!onScan.isAttachedTo(document) && onScan.attachTo(document, {
				onScan: (scanValue, iQuantity) => {
					console.log(`${iQuantity}x ${scanValue}`);
					dispatch(addScan(scanValue));
				},
			});
		} else {
			console.log('Scan::: Not allowed');
		}
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		init();
	}, [user]);

	useEffect(() => {
		console.log('New scans', scans);
	}, [scans]);

	return false && process.env.NODE_ENV === 'development' && (
		<div className="pb-5">
			<h6>Scan</h6>
			{products.filter(product => product.gtin).map(product => (
				<Button key={product._id} size="sm" onClick={() => onScan.simulate(document, product.gtin)}>{product.name}</Button>
			))}
			<Button onClick={() => dispatch(clearScans())}>Clear scans</Button>
		</div>
	);
}

ScannerInit.propTypes = {
	dispatch: PropTypes.func.isRequired,
	user: PropTypes.object,
	scans: PropTypes.arrayOf(PropTypes.string),
	products: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (store, props) => ({
	user: getLoggedUser(store),
	scans: getScans(store),
	products: getProducts(store),
});

export default connect(mapStateToProps)(ScannerInit);
