import React, { Component, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Button, Form } from 'react-bootstrap';

import ReactGA4 from 'react-ga4';

import config from '../../../../config.js';

import { setCartItem, removeItem, getCartItemByProduct, getCartItemFromProduct, formatAnalyticsItems, getCartQuantityStep, setCartSubscriptionActive, getCartSubscription } from '../../CartActions.js';
import { getProductOption, getProductPrice, memoizedGetProducts } from '../../../Product/ProductActions.js';
import { getCurrentCurrency, defaultCurrency } from '../../../Currency/CurrencyActions.js';
import { getTranslation } from '../../../Intl/IntlHelper.js';
import { addUserInterestsRequest, getCurrentUserData } from '../../../User/UserActions.js';
import { getUserGroup, doesUserGroupHasCondition, getUserGroupCondition, getUserGroupOption } from '../../../User/UserGroupActions.js';
import { getConfig } from '../../../Config/ConfigActions.js';
import { getReferral, setStatusCode } from '../../../App/AppActions.js';

// import PaypalMessagePayIn4 from '../../../Payment/components/paypal/PaypalMessagePayIn4.js';
import Icon from '../../../../components/Content/Icon.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';
import ProductSpecialPriceQuantityInfo from '../../../Product/components/view/ProductSpecialPriceQuantityInfo.js';

function CartItemQuantitySelector({ dispatch, intl, product, variation = null, item = null, subscription = null, products = [], userData = {}, userGroup = null, disableQuantityEdition = false, disableExtraMessages = false, needFillBox = false, buttonColor = null, isSubscriptionDisabled = false, referral = null, currency = defaultCurrency, onCart = false, onAddToCart = null, onChangeVariation = null }) {
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);

    const getProduct = () => {
        return variation || product;
    };

    const handleTrackAnalytics = (quantity, diff = 0) => {
        if(diff !== 0) {
            const product = getProduct();
            const productPrice = getProductPrice(product, 1, userGroup, true, referral);
            if(typeof window !== 'undefined' && config.google.analytics.trackingCodeGA4) {
                // console.log('Analytics::: Track add_to_cart', quantity, diff);

                // GA: New
                ReactGA4.event(diff > 0 ? 'add_to_cart' : 'remove_from_cart', {
                    currency,
                    value: productPrice,
                    items: formatAnalyticsItems([{ ...product, priceTTC: productPrice, quantity }]),
                });
            }
            if(typeof window !== 'undefined' && window.fbq) {
                diff > 0 && window.fbq('track', 'AddToCart', {
                    content_name: product.name,
                    content_ids: [product.slug || product._id],
                    content_type: 'product',
                    value: productPrice,
                    currency,
                });
            }

            dispatch(addUserInterestsRequest(userData, product.categories));
        }
    };

    const handleAddToCart = () => {
        onAddToCart && onAddToCart();
    };

    const handleChangeQuantity = newQuantity => {
        if(newQuantity > 0) {
			dispatch(setCartItem(getCartItemFromProduct({ ...getProduct(), variationParentId: variation && (product || {})._id }, newQuantity, intl.locale, userGroup, { referral })));
            handleAddToCart();
        } else {
			dispatch(removeItem(item));
        }
		handleTrackAnalytics(newQuantity, newQuantity - ((item || {}).quantity || 0));
    };

    const handleSubtract = () => {
        if((item || {}).quantity > 0) {
            let newQuantity = (item || {}).quantity - getCartQuantityStep(getProduct(), userGroup);
            if(getProduct().minimumQuantity && newQuantity < getProduct().minimumQuantity) {
                newQuantity = 0;
            } else {
                newQuantity = Math.max(getProduct().minimumQuantity || 1, newQuantity);
            }
            handleChangeQuantity(newQuantity);
        }
    };

    const handleAdd = event => {
		event && event.stopPropagation();
		const product = getProduct();
        handleChangeQuantity(Math.max(product.minimumQuantity || 1, ((item || {}).quantity || 0) + getCartQuantityStep(product, userGroup)));
    };

    const handleChange = event => {
        handleChangeQuantity(event.target.value);
    };

    const handleFillBox = () => {
        const product = getProduct();
        handleChangeQuantity(item ? (Math.ceil(item.quantity / item.packing) * item.packing) : product.packing);
    };

	const handleSubscribe = event => {
		!item?.quantity && handleAdd(event);
		dispatch(setCartSubscriptionActive());
	};

    const renderVariations = () => {
        if(!onCart && (product.variations || []).length && onChangeVariation) {
            const variations = product.variations.filter(variation => {
                const product = products.find(product => product._id === variation.productId);
                return product && (!(getProductOption(product, 'visibleGroups') || []).length || getProductOption(product, 'visibleGroups').includes((userGroup || {}).identifier));
            });
            if(variations.length) {
                return (
                    <Form.Select name="variation" value={(item || {}).productId || (variation || product)._id || ''} onChange={event => onChangeVariation(event.target.value)}>
                        {
                            variation
                            ? <option value="">{getTranslation(product, 'name', intl.locale)}</option>
                            : <FormattedMessage id="selectOptions" defaultMessage="Select an option">{message => <option value="">{message}</option>}</FormattedMessage>
                        }
                        {variations.map((variation, index) => (
                            <option key={variation.productId} value={variation.productId}>{variation.label}</option>
                        ))}
                    </Form.Select>
                );
            }
        }
    };

	const quantity = mounted && item ? item.quantity : 0;
	return (
		<Fragment>
			{renderVariations()}

			<small className="d-block text-center text-md-start">
				{(product.minimumQuantity || 1) > getCartQuantityStep(getProduct(), userGroup) && <em className="d-block"><Icon icon="info" /> <FormattedMessage id="productMinimumQuantityAlert" defaultMessage="The minimum purchase quantity is {count} pieces" values={{ count: product.minimumQuantity }} /></em>}
				{(product.stepQuantity || 1) > getCartQuantityStep(getProduct(), userGroup) && <em className="d-block"><Icon icon="info" /> <FormattedMessage id="producStepQuantityAlert" defaultMessage="Purchase in increments of {count} pieces" values={{ count: product.stepQuantity }} /></em>}
			</small>
			<Row className="mb-1 text-center text-lg-start">
				{!disableQuantityEdition && (quantity > 0 || onCart) && (
					<Col xs="12" xl={onCart ? 12 : 4}>
						{false && <span className="d-block mb-2 text-primary"><FormattedMessage id="quantity" defaultMessage="Quantity" /></span>}

						<div className="d-flex p-1 p-md-2 bg-info rounded-sm">
							<span><Button variant="link" size="sm" className="px-0 px-md-2 qty-control" onClick={handleSubtract}><Icon icon="remove" /></Button></span>
							<Form.Control type="text" name="quantity" size="sm" value={quantity} disabled={getCartQuantityStep(getProduct(), userGroup) !== 1} className="border-0 bg-info text-dark text-center font-weight-bold text-lg" onChange={handleChange} />
							<span><Button variant="link" size="sm" className="px-0 px-md-2 qty-control" onClick={handleAdd}><Icon icon="add" /></Button></span>
						</div>

						{false && needFillBox && (
							<Button variant="link" block size="sm" className="m-0 mt-1 p-0" onClick={handleFillBox}><Icon icon="box" /> <FormattedMessage id="cartItemBoxFill" defaultMessage="Fill the box" /></Button>
						)}
					</Col>
				)}
				{!onCart && (
					<Col className="text-center text-lg-end">
						<div className="d-grid">
							<Button variant={buttonColor || 'secondary'} size="lg" className="rounded-sm mt-1 add-to-cart" onClick={handleAdd}><Icon icon="shopping-cart" /> <FormattedMessage id="cartAddTo" defaultMessage="Add to cart" /></Button>
							{quantity > 0 && <Button variant="link" size="lg" className="text-success see-cart" as={Link} to={`/${intl.locale}/cart`}><FormattedMessage id="cartSee" defaultMessage="See my cart" /> <Icon icon="chevron-right" /></Button>}
						</div>
						{/* {!disableExtraMessages && <PaypalMessagePayIn4 amount={getProductPrice(product, quantity, userGroup, true, referral)} />} */}
					</Col>
				)}
			</Row>
			{onCart && <ProductSpecialPriceQuantityInfo product={product} />}
			{!onCart && !isSubscriptionDisabled && getUserGroupOption(userGroup, 'allowSubscription') && (variation || product).isPack && (
				<div className={classNames('my-1 p-1 text-center text-sm-start bg-opacity-10 rounded text-sm', subscription?.isActive ? 'bg-success' : 'bg-danger')}>
					{
						subscription?.isActive
						? (
							<div className="p-2">
								<CmsPanel slug="product-subscription-addedto" />
							</div>
						) : (
							<Row className="g-0 g-sm-2 ">
								<Col>
									<Button variant="outline-danger" size="sm" className="m-0 mt-lg-3 mt-xl-0 w-100" onClick={handleSubscribe}><Icon icon="sync" /> <FormattedMessage id="cartAddTosubscription" defaultMessage="I subscribe to this box" /></Button>
								</Col>
								<Col xs="12" lg="8">
									<CmsPanel slug="product-subscription-addto" />
								</Col>
							</Row>
						)
					}
				</div>
			)}
		</Fragment>
	);
}

function mapStateToProps(store, props) {
    return {
        currency: getCurrentCurrency(store),
        userData: getCurrentUserData(store),
        userGroup: getUserGroup(store),
        products: memoizedGetProducts(store, { isActive: true, isVariation: true }),
        item: getCartItemByProduct(store, props.variation || props.product),
        needFillBox: doesUserGroupHasCondition(store, 'packFill'),
        referral: getReferral(store),
		subscription: getCartSubscription(store),
		isSubscriptionDisabled: getConfig(store, 'is-subscription-disabled'),
    };
}

CartItemQuantitySelector.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    currency: PropTypes.string,
    userData: PropTypes.object,
    product: PropTypes.object.isRequired,
    variation: PropTypes.object,
    userGroup: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object),
    item: PropTypes.object,
    onCart: PropTypes.bool,
    disableQuantityEdition: PropTypes.bool,
    disableExtraMessages: PropTypes.bool,
    onAddToCart: PropTypes.func,
    onChangeVariation: PropTypes.func,
    needFillBox: PropTypes.bool,
    buttonColor: PropTypes.string,
    referral: PropTypes.string,
	subscription: PropTypes.object,
	isSubscriptionDisabled: PropTypes.bool,
};

export default connect(mapStateToProps)(injectIntl(CartItemQuantitySelector));
