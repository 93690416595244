import callApi from '../../util/apiCaller.js';
import { getHashKeys } from '../../util/security.js';

// Export Constants
export const INIT_SOCKET = 'INIT_SOCKET';
export const SEND_SOCKET = 'SEND_SOCKET';
export const RECEIVE_SOCKET = 'RECEIVE_SOCKET';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_STATUS_CODE = 'SET_STATUS_CODE';
export const SET_REDIRECT = 'SET_REDIRECT';
export const REDIRECT = 'REDIRECT';
export const SET_MOUNTED = 'SET_MOUNTED';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const INIT_PERSISTED_DATA = 'INIT_PERSISTED_DATA';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_SSR_MODE = 'SET_SSR_MODE';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_APP_LOADED = 'SET_APP_LOADED';
export const SET_REFERRAL = 'SET_REFERRAL';
export const SET_FORCE_CANONICAL = 'SET_FORCE_CANONICAL';
export const SET_POPIN_ACTIVE = 'SET_POPIN_ACTIVE';
export const ADD_SCAN = 'ADD_SCAN';
export const CLEAR_SCANS = 'CLEAR_SCANS';

export const FETCH_LIMIT = 100;
export const REFERRAL_QUERY_PARAM = 'referral';

export function getWebVitalsRequest() {
	return callApi('app/webvitals').then(res => {
        return res.ok;
    }).catch(error => {
        return null;
    });
}

export function getTable(store, tableId) {
    return store.app.tables.find(table => table.id === tableId) || {};
}

export function isFetching(store, type) {
    return (store[type] || {}).isFetching || false;
}

export function isSSR(store) {
    return store.app.isSSR;
}

export function getRedirect(store) {
    return store.app.redirect;
}

export function getStatusCode(store) {
    return store.app.statusCode;
}

export function getReferral(store) {
    return store.app.referral;
}

export function isCanonicalForced(store) {
    return store.app.forceCanonical;
}

export function isPopinActive(store) {
    return store.app.isPopinActive;
}

export function getScans(store) {
	return store.app.scans;
}

export function getLastScan(store) {
	const scans = getScans(store) || [];
	return scans[scans.length - 1];
}

export function initSocket(socket) {
    return {
        type: INIT_SOCKET,
        socket,
    };
}

export function sendSocket(datas) {
    // Secure subscribe socket with key
    if(datas && datas.type === 'subscribe' && typeof datas.data === 'object') {
        datas.data = {
            ...(datas.data || {}),
            ...getHashKeys(datas.data.channel),
        };
    }
    return {
        type: SEND_SOCKET,
        data: datas,
    };
}

export function receiveSocket(datas) {
    return {
        type: RECEIVE_SOCKET,
        data: datas,
    };
}

export function toggleMenu() {
    return {
        type: TOGGLE_MENU,
    };
}

export function setRedirect(redirect = '', statusCode = '301') {
    // console.log('AppActions::: setRedirect', redirect, statusCode);
    return {
        type: SET_REDIRECT,
        redirect,
        statusCode,
    };
}

export function setStatusCode(statusCode) {
    // console.log('AppActions::: setStatusCode', statusCode);
    return {
        type: SET_STATUS_CODE,
        statusCode,
    };
}

export function redirect() {
    return {
        type: REDIRECT,
    };
}

export function setMounted() {
    return {
        type: SET_MOUNTED,
    };
}

export function updateTable(tableId, tableData) {
    return {
        type: UPDATE_TABLE,
        tableId,
        tableData,
    };
}

export function initPersistedData() {
    return {
        type: INIT_PERSISTED_DATA,
    };
}

export function setIsFetching(dataType, isFetching = true) {
    return {
        type: SET_IS_FETCHING,
        dataType: dataType.toLowerCase(),
        isFetching,
    };
}

export function setAppSSRMode(isSSR) {
    return {
        type: SET_SSR_MODE,
        isSSR,
    };
}

export function setAppIsMobile(isMobile) {
    return {
        type: SET_IS_MOBILE,
        isMobile,
    };
}

export function setAppLoaded(isLoaded = true) {
    return {
        type: SET_APP_LOADED,
        isLoaded,
    };
}

export function setReferral(referral) {
    return {
        type: SET_REFERRAL,
        referral,
    };
}

export function setForceCanonical(isCanonical) {
    return {
        type: SET_FORCE_CANONICAL,
        isCanonical,
    };
}

export function setPopinActive(isPopinActive) {
    return {
        type: SET_POPIN_ACTIVE,
        isPopinActive,
    };
}

export function addScan(value) {
	return {
		type: ADD_SCAN,
		value,
	};
}

export function clearScans(reference = null) {
	return {
		type: CLEAR_SCANS,
		reference,
	};
}
